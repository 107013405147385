@use '@angular/material' as mat;
@use 'src/app/styles/m3-theme.scss';
@use 'sass:map';

// Be sure that you only ever include this mixin once!
@include mat.core();
@include mat.color-variants-backwards-compatibility(m3-theme.$light-theme);

body {
	// Apply the light theme by default
	@include mat.all-component-themes(m3-theme.$light-theme);
	@include mat.all-component-typographies(m3-theme.$light-theme);
	@include mat.datepicker-density(0);
}

p,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
mat-list-item,
mat-icon,
mat-button {
	font-family: 'Fira Sans Condensed', sans-serif;
}

// icon accent
.mat-accent-icon {
	color: map.get(m3-theme.get-palette('secondary'), 50);
}

// icon error
.mat-error-icon {
	color: map.get(m3-theme.get-palette('error'), 50);
}

// spinner accent
.mat-accent-spinner .mat-progress-spinner circle,
.mat-accent-spinner .mat-spinner circle {
	stroke: map.get(m3-theme.get-palette('secondary'), 50);
}

//spinner error
.mat-error-spinner .mat-progress-spinner circle,
.mat-error-spinner .mat-spinner circle {
	stroke: map.get(m3-theme.get-palette('error'), 50);
}

.mat-error-toggle .mat-slide-toggle-thumb {
	background-color: map.get(m3-theme.get-palette('error'), 50); // Adjust the shade as needed
}

.mat-error-toggle .mat-slide-toggle-bar {
	background-color: map.get(m3-theme.get-palette('error'), 50); // Adjust the shade as needed
}

.mat-accent-toggle .mat-slide-toggle-thumb {
	background-color: map.get(m3-theme.get-palette('secondary'), 50); // Adjust the shade as needed
}

.mat-accent-toggle .mat-slide-toggle-bar {
	background-color: map.get(m3-theme.get-palette('secondary'), 50); // Adjust the shade as needed
}

.mat-error-progress .mat-progress-bar-fill::after {
	background-color: map.get(m3-theme.get-palette('error'), 50); // Adjust the shade as needed
}

.mat-error-progress .mat-progress-bar-buffer {
	background-color: map.get(m3-theme.get-palette('error'), 50); // Adjust the shade as needed
}

.mat-card-header .mat-card-subtitle {
	color: map.get(m3-theme.get-palette('secondary'), 70);
}
