/* You can add global styles to this file, and also import other style files */
@import 'src/theme.scss';

html,
body {
	height: 100%;
	margin: 0;
}

.theme-container {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

//highlight hover on table rows
mat-row:hover {
	transition: all 0.3s ease;
	background: rgba(0, 0, 0, 0.05);
}

.dropzone {
	height: auto;
	margin: 10px 0px;
	border: 2px dotted;
	border-radius: 10px;
}

.dropzone-content {
	font: 500;
}

.mat-dialog-background .mat-dialog-container {
	background-color: #f5f5f5;
	overflow-y: auto;
	max-height: 90vh;
	height: auto;
}

.mat-card-actions {
	gap: 10px;
}

.mat-card-content {
	margin-top: 20px;
	font-family: 'Fira Sans Condensed, sans-serif';
}

/* This applies a full width (100%) on screens larger than 599px */
@media (min-width: 600px) {
	.flex-full-width {
		flex: 1 0 100%; /* You can also use 'width: 100%;' if you prefer */
	}
}

.mat-mdc-card-subtitle {
	color: #757575 !important;
}
